<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('identity.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row class="spacing">
        <van-form ref="form">
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('identity.realname') }}</span>
            </van-row>
            <van-field
              v-model="identity.realname"
              :placeholder="$t('identity.notice1')"
              :rules="[{ required: true, message: '該項是必填項' }]"
            />
          </van-row>

          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('identity.regions') }} </span>
            </van-row>
            <van-field
              v-model="identity.country"
              :placeholder="$t('identity.notice2')"
              :rules="[{ required: true, message: '該項是必填項' }]"
            />
          </van-row>
       <van-row class="field">
          <van-row
            style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
            ><span>{{ $t('identity.address') }} </span>
          </van-row>
          <van-field
            v-model="identity.address"
            :placeholder="$t('identity.notice3')"
            :rules="[{ required: true, message: '該項是必填項' }]"
          />
        </van-row>

        <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('gjrz.phone') }} </span>
            </van-row>
            <van-field
              v-model="identity.address1"
              :placeholder="$t('gjrz.notice3')"
              :rules="[{ required: true, message: '該項是必填項' }]"
            />
          </van-row>


         <!-- <van-row class="uploading-image field" >
            <van-row style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;">
              <span>{{ $t('gjrz.sfzzm') }}</span>
            </van-row>
            <van-uploader :beforeRead="beforeRead" :after-read="aftersfza">
              <img
                :src="sfza ? sfza : ''"
                ref="sfza"
                v-if="sfza"
                style="max-width:100%;max-height:10rem;text-align:center"
              />
            </van-uploader>
          </van-row>

          <van-row class="uploading-image field">
            <van-row style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;">
              <span>{{ $t('gjrz.sfzfm') }}</span>
            </van-row>
            <van-uploader :beforeRead="beforeRead" :after-read="aftersfzb">
              <img
                :src="sfzb ? sfzb : ''"
                ref="sfzb"
                v-if="sfzb"
                style="max-width:100%;max-height:10rem;text-align:center"
              />
            </van-uploader>
          </van-row> -->
        </van-form>
        <van-button
          v-if="identity.oauth === '0'"
          class="submitBtn"
          @click.prevent="onSubmit"
          >{{ $t('common.submit') }}</van-button
        >
        <van-button v-if="identity.oauth === '1'" class="submitBtn">{{
          $t('identity.auth1')
        }}</van-button>
        <van-button v-if="identity.oauth === '2'" class="submitBtn">{{
          $t('identity.auth2')
        }}</van-button>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      identity: {},
      rules: [],
      sfza: '',
      sfzb: '',
      address: '',
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/home/getindentity')
      if (data) {
        if (data.code === 200) {
          this.identity = data.data
          // this.sfza = data.data.sfza
          // this.sfzb = data.data.sfzb
        }
      }
    },
    beforeRead(file) {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif'
      ) {
        this.$toast(this.$t('gjrz.toast'))
        return false
      }
      return true
    },
    async aftersfza(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.sfza = data.data.url
      }
    },
    async aftersfzb(file) {
      const param = new FormData()
      param.append('file', file.file)
      const { data } = await this.$http.post('/home/user/uploadimg', param)
      if (data) {
        this.sfzb = data.data.url
      }
    },
    onSubmit() {
      this.$refs.form
        .validate()
        .then(async () => {
          const { data } = await this.$http.post('/home/home/identity', {
            realname: this.identity.realname,
            country: this.identity.country,
            address1: this.identity.address1,
            address: this.identity.address,
            // sfza: this.sfza,
            // sfzb: this.sfzb
          })
          if (data) {
            if (data.code === 200) {
              this.$toast.success(this.$t('common.success'))
              setTimeout(() => {
                this.$router.push('/index')
              }, 300)
            }
          }
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.53333rem;
  background: #f7f7f7;
  height: 100vh;
  .spacing {
    margin-top: 1rem;
    .field {
      background-color: #f1f1f2;
      margin-top: 0.53333rem;
      width: 90%;
      margin-left: 5%;
      border-radius: 0.53333rem;
      padding: 1.06667rem;
      span {
        color: #000;
        font-size: 0.86667rem;
      }
      .van-cell {
        font-size: 0.98667rem;
        background-color: #f1f1f2 !important;
        color: #fff !important;
        height: 4rem;
      }
      .van-field__control {
        padding-right: 1.2rem;
      }
      /deep/.van-field input {
        color: #000;
        text-align: center;
      }
      /deep/.van-field__error-message {
        text-align: center;
      }
    }
    .submitBtn {
      margin-top: 10%;
      width: 80%;
      letter-spacing: 0.53333rem;
      height: 4rem;
      font-size: 0.53333rem;
      color: #fff;
      background-color: #004ea3;
      border-radius: 0.53333rem;
      border: none;
      box-shadow: 0 0 1.33333rem #004ea3;
    }
  }
}
</style>
